import React, { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../Constant";
// import { Link } from "react-router-dom";
import images from "../../data/imageUpload";
import Header from "../../components/header";
import Footer from "../../components/footer";
import BlogImg1 from "../../assets/images/blog-img02.svg";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function Newsdetail() {
  const [news, setNews] = useState([]);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    getNewsDetail();
  }, []);
  let slugRedirection = null;
  slugRedirection = searchParams.get("slug").trim();
  async function getNewsDetail() {
    let response = await axios.get(
      `${apiUrl}get-news-detail?slug=${slugRedirection}`
    );
    if (response.status === 201) {
      console.log("24", response.data);
      setNews(response.data[0]);
    }
  }
  console.log("49", news);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Recent Job News - Softuvo</title>
        {/* <link rel="canonical" href="https://career.softuvo.click/news.html" /> */}
        <meta name="description" content="Softuvo Career presents career news and every detail happening in the office premise. Stay tuned for inhouse updates." />
      </Helmet>
      <Header />
      <section class="banner banner--news">
        <div className="banner__commonBanner">
          <div className="commonPY">
            <div className="container">
              <div className="row">
                <div className="col-md-6 alignCenter">
                  <div>
                    <h1>Inside Softuvo</h1>
                    <p>
                      Sip your coffee and browse through the latest happenings
                      at Softuvo! From a collection of little stories about our
                      people, our capabilities to the changing face of our
                      company all under one umbrella!
                    </p>
                  </div>
                </div>
                <div className="col-md-6  text-end">
                  <img
                    src={images["contact-banner.svg"]}
                    alt="Contact Banner"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="newsDetail commonPY">
        <div class="container">
          <div class="newsContent">
            <div class="row">
              <div class="col-lg-6">
                <h5>{news.title}</h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html: news.content,
                  }}
                ></p>
              </div>
              <div className="col-lg-6">
                <img src={news.fileName} alt="blog1" />
              </div>
              {/* <div class="col-lg-6">
                            <img src="images/news-detail2.png" alt="News Detail"
                                class="img-fluid newsContent__rightImg" />
                        </div> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Newsdetail;

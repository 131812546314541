import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import ContactImg from "../../assets/images/contact-banner.svg";
import ApplicationProcess from "../applicationProcess";
import Blog from "../../components/blogs";
import images from "../../data/imageUpload";
import Header from "../../components/header";
import Footer from "../../components/footer";
import apiUrl from "../../Constant";
import { useContext } from "react";
import AuthContext from "../../store/auth-context";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

function Jobs() {
  const { id } = useParams();
  const { title } = useLocation();

  useEffect(() => {
    console.log("getdepartment", id);
    loadData(id);
  }, [id]);

  const [jobs, setJob] = useState([]);
  const [job, setJobs] = useState([]);
  const [subscribeEmail, setSubscribeEmail] = useState("");

  const [nameFilter, setNameFilter] = useState("");
  const [skills, setSkills] = useState("");
  const authCtx = useContext(AuthContext);
  let token = authCtx.token;
  async function loadData(id) {
    const response = await fetch(apiUrl + "get-department-jobs", {
      method: "POST",
      body: JSON.stringify({
        department: id,
      }),
      headers: { "Content-Type": "application/json" },
    });
    const result = await response.json();
    setJob(result.job);
  }

  async function getJobs() {
    let response = await axios({
      url: apiUrl + "view-jobs",
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    setJobs(response.data.jobs);
  }
  useEffect(() => {
    getJobs();
  }, []);

  let filterjobHandler = jobs.filter(({ position, department, skills }) =>
    position || nameFilter
      ? position === nameFilter ||
      position.toLowerCase().includes(nameFilter.toLowerCase())
      : true
  );

  let sendSubscribeEmail = async (event) => {
    event.preventDefault();
    let response = await axios.post(`${apiUrl}subscribe-updates`, {
      email: subscribeEmail,
    });
    if (response.status === 200) {
      toast.dismiss();
      toast.success("Thanks, for subscribing");
    }
    setSubscribeEmail('');
  }
  const ReadMore = ({ children }) => {
    const text = children;
    console.log("79", children)
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.substring(0, 150) : text}
        {/* <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...read more" : " show less"}
        </span> */}
      </p>
    );
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Current openings at Softuvo - Softuvo Career</title>
        {/* <link rel="canonical" href="https://career.softuvo.click/job-search.html" /> */}
        <meta name="description" content="We are always on the hunt for talented people! Find development jobs that match your skills and begin your journey with us!" />
      </Helmet>
      <Header />
      <section className="jobSearch">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Jobs</h2>
              <p>
                We are always on the hunt for talented people!
                <br />
                Find jobs that match your skills and get ready to start your
                journey with us!
              </p>

              {/* <form className="jobSearch__form">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter the job title"
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.target.value)}
                />
                <button className="btn jobSearch__form__button">Search</button>
              </form> */}
            </div>
          </div>
        </div>
      </section>

      <section className="jobListing">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3>
                {nameFilter ? filterjobHandler.length : jobs.length} User
                Experience Jobs
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="jobListing__left">
                <div className="row">
                  {filterjobHandler.map((value) => {
                    return (
                      <div className="col-lg-5">
                        <div className="jobListing__left__inner">
                          <h4>
                            {/* UI */}
                            {value.position}
                            <br />
                            <span>Experience :{value.experience}</span>
                          </h4>
                          <p className="jobsContent-section mb-0"><strong>Skills: </strong>
                            <span
                              className="jobSkills"
                              dangerouslySetInnerHTML={{
                                __html: value.skills,
                              }}
                            ></span></p>
                          {/* <ReadMore> */}
                          {/* <span
                              dangerouslySetInnerHTML={{
                                __html: value.description.substring(0,200),
                              }}
                            ></span> */}
                          {/* </ReadMore> */}
                          {/* <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Senior Level
                            </label>
                          </div> */}
                          {/* <button className="btn jobListing__left__inner__applyButton" href="/job-detail">
                            Apply
                          </button> */}
                          {/* <a
                            className="btn jobListing__left__inner__applyButton"
                            href="/job-listings"
                          >
                            Apply
                          </a> */}
                          <Link
                            to={"/job-listings/" + value._id}
                            className="btn jobListing__left__inner__applyButton"
                          >
                            Apply
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="jobListing__right">
                <div className="jobListing__right__registerJobAlerts">
                  <h3>Subscribe for Latest News and Job Updates!</h3>
                  <form className="jobListing__right__registerJobAlerts__form">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email address"
                      value={subscribeEmail}
                      onChange={(e) => setSubscribeEmail(e.target.value)}
                    />
                    <button
                      className="btn jobListing__right__registerJobAlerts__form__button"
                      onClick={(event) => sendSubscribeEmail(event)}
                    >
                      Activate
                    </button>
                  </form>
                </div>
                <div className="jobListing__right__currentJobOpenings">
                  <h3>Current Job Openings</h3>
                  <ul>
                    {jobs.length != 0 ? (
                      jobs.map((value) => {
                        console.log("maosp", value);
                        return (
                          <li>
                            <h4>{value.department}</h4>
                            <p

                              dangerouslySetInnerHTML={{
                                __html: value.position,
                              }}
                            ></p>
                          </li>
                        );
                      })
                    ) : (
                      <div><h3>No Jobs Found</h3></div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ApplicationProcess />

      <section className="weHiring weHiring--bgPurple">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="weHiring__left">
                <img
                  src={images["we-are-hiring2.svg"]}
                  alt="We are hiring"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6 sectionContent">
              <div className="weHiring__right">
                <h2>It’s Not Just A Job, It’s A Wonderful Opportunity!</h2>
                <p>
                  Explore our exciting job opportunities and fill the one
                  <br /> that fits your passion.
                </p>
                <Link
                  to="/job-detail"
                  className="commonButton commonButton--yellow m-auto"
                >
                  Explore More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <Blog />
      </div>
      <Footer />
    </div>
  );
}
export default Jobs;

import React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/softuvo_logo_green.png";
import JoinImg from "../../assets/images/logo-white.png";
const AdminHeader = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let token = localStorage.getItem("token-info");
    if (token === null || token === undefined || token === "") {
      navigate("/admin/login");
    }
  }, []);

  return (
    <div>
      <header id="header">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container">
            <a href="/" className="navbar-brand">
              <img src={JoinImg} className="img-fluid" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link className="nav-link" to="/why-join-softuvo">
                      Why Join Softuvo
                    </Link>
                 </ul>
              </div> */}
          </div>
        </nav>
      </header>
    </div>
  );
};
export default AdminHeader;

import React from "react";
import Blog from "../../components/blogs";
import TeamImg from "../../assets/images/ourteam-banner.svg";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Helmet } from "react-helmet";
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("../../assets/images", false, /\.(png|jpe?g|svg)$/)
);

function Team() {
  const data = [
    {
      // url: "https://career.softuvo.click/images/meenu-gupta1.png",
      url: images["meenu-gupta.png"],
      full_name: "Meenu Gupta",
      designation: "HR Head",
    },
    // {
    //   url: "https://career.softuvo.click/images/amit-saini1.png",
    //   url: images["amit-saini1.png"],
    //   full_name: "Amit Saini",
    //   designation: "HR Manager",
    // },
    {
      // url: "https://career.softuvo.click/images/akanksha.png",
      url: images["akanksha.png"],
      full_name: "Akanksha",
      designation: "HR Executive",
    },
    {
      // url: "https://career.softuvo.click/images/sapna-koul.jpg",
      url: images["sapna-koul.jpg"],
      full_name: "Sapna Koul",
      designation: "HR Generalist",
    },
    {
      // url: "https://career.softuvo.click/images/ES-diksha.png",
      url: images["ES-diksha.png"],
      full_name: "Diksha Suri",
      designation: "Team Lead Content & Social Media Marketing",
    },
    {
      // url: "https://career.softuvo.click/images/karanjot.png",
      url: images["karanjot.png"],
      full_name: "Karanjot",
      designation: "Content Writer",
    },
    {
      // url: "https://career.softuvo.click/images/Gurmeet.jpg",
      url: images["Gurmeet.jpg"],
      full_name: "Gurmeet",
      designation: "Digital Marketing Executive",
    },
    {
      // url: "https://career.softuvo.click/images/anita-mehra.png",
      url: images["anita-mehra.png"],
      full_name: "Anita Mehra",
      designation: "Project Manager",
    },
    {
      // url: "https://career.softuvo.click/images/akshit.jpg",
      url: images["akshit.jpg"],
      full_name: "Akshit Issar",
      designation: "Project Coordinator",
    },
    {
      // url: "https://career.softuvo.click/images/aakshi.jpg",
      url: images["aakshi.jpg"],
      full_name: "AAKSHI THAKUR",
      designation: "Project coordinator",
    },
    {
      // url: "https://career.softuvo.click/images/neha.png",
      url: images["neha.png"],
      full_name: "Neha Guleria",
      designation: "Sr. UI/UX Designer",
    },
    {
      // url: "https://career.softuvo.click/images/manjeet1.png",
      url: images["manjeet1.png"],
      full_name: "Manjeet Kaur",
      designation: "Web Designer",
    },
    {
      // url: "https://career.softuvo.click/images/navdeep.png",
      url: images["navdeep.png"],
      full_name: "Navdeep Singh",
      designation: "Team Lead (UI Developer)",
    },
    {
      // url: "https://career.softuvo.click/images/Shamsheer.jpeg",
      url: images["Shamsheer.jpeg"],
      full_name: "Shamsheer Singh",
      designation: "UI Developer",
    },
    {
      // url: "https://career.softuvo.click/images/mayank.png",
      url: images["mayank.png"],
      full_name: "Mayank Sharma",
      designation: "Sr. UI Developer",
    },
    {
      // url: "https://career.softuvo.click/images/Jigs.jpeg",
      url: images["Jigs.jpeg"],
      full_name: "Jignesh",
      designation: "Software Engineer/Developer (Python)",
    },
    {
      // url: "https://career.softuvo.click/images/Ritambhara.jpg",
      url: images["Ritambhara.jpg"],
      full_name: "Ritambhra Thakur",
      designation: "Software Engineer(Python)",
    },
    {
      // url: "https://career.softuvo.click/images/gaurav.png",
      url: images["gaurav.png"],
      full_name: "Gaurav",
      designation: "Software Engineer(Python)",
    },
    {
      // url: "https://career.softuvo.click/images/sahil.jpg",
      url: images["sahil.jpg"],
      full_name: "Sahil",
      designation: "Jr. Software Engineer(Python)",
    },
    {
      // url: "https://career.softuvo.click/images/amit.png",
      url: images["amit.png"],
      full_name: "Kumar Amit",
      designation: "Sr. Software Tester",
    },
    {
      // url: "https://career.softuvo.click/images/gamanjit.png",
      url: images["gamanjit.png"],
      full_name: "Gamanjit Singh",
      designation: "Delivery Manager",
    },
    {
      // url: "https://career.softuvo.click/images/anil-sahni.jpg",
      url: images["anil-sahni.jpg"],
      full_name: "Anil Sahni",
      designation: "Front End Developer(React.JS)",
    },
    {
      url: images["sachin-rana.jpeg"],
      full_name: "Sachin Rana",
      designation: "Web Developer",
    },
    {
      // url: "https://career.softuvo.click/images/itender.png",
      url: images["itender.png"],
      full_name: "Itender",
      designation: "Sr. Business Development Manager",
    },
    {
      // url: "https://career.softuvo.click/images/rohit.png",
      url: images["rohit.png"],
      full_name: "Rohit Kalra",
      designation: "Business Development Manager",
    },
    {
      // url: "https://career.softuvo.click/images/neeraj.png",
      url: images["neeraj.png"],
      full_name: "Neeraj",
      designation: "Business Development Executive",
    },
    {
      // url: "https://career.softuvo.click/images/Sagar.jpg",
      url: images["Sagar.jpg"],
      full_name: "Sagar",
      designation: "Business Development Executive",
    },
    {
      // url: "https://career.softuvo.click/images/rashmi.jpg",
      url: images["rashmi.jpg"],
      full_name: "Rashmi Arora",
      designation: "Business Development Executive",
    },
    {
      // url: "https://career.softuvo.click/images/aman.png",
      url: images["aman.png"],
      full_name: "Aman",
      designation: "Front End Developer(Angular)",
    },
    {
      // url: "https://career.softuvo.click/images/tarun.jpg",
      url: images["tarun.jpg"],
      full_name: "Tarun Lakhra",
      designation: "Node.JS Developer",
    },
    {
      // url: "https://career.softuvo.click/images/Jatin.jpg",
      url: images["Jatin.jpg"],
      full_name: "Jatin",
      designation: "Associate Software Engineer(Nodejs)",
    },
    {
      // url: "https://career.softuvo.click/images/Naveen.jpeg",
      url: images["naveen1.jpg"],
      full_name: "Naveen Malik",
      designation: "Full Stack Developer",
    },
    {
      // url: "https://career.softuvo.click/images/akash.jpg",
      url: images["akash.jpg"],
      full_name: "Akash Bhatt",
      designation: "Front End Developer(React.JS)",
    },
    {
      // url: "https://career.softuvo.click/images/satyandra.png",
      url: images["satyandra.png"],
      full_name: "Satyandra",
      designation: "Sr. Software Engineer",
    },
    {
      // url: "https://career.softuvo.click/images/sanjana.png",
      url: images["sanjana.png"],
      full_name: "Sanjana",
      designation: "Sr. Software Developer",
    },
    {
      // url: "https://career.softuvo.click/images/RohitChawla.jpeg",
      url: images["RohitChawla.jpeg"],
      full_name: "Rohit Chawla",
      designation: "Software Developer(PHP)",
    },
    {
      // url: "https://career.softuvo.click/images/SandeepKumar.jpg",
      url: images["SandeepKumar.jpg"],
      full_name: "Sandeep",
      designation: "DevOps Engineer",
    },
    {
      // url: "https://career.softuvo.click/images/Sushil.jpg",
      url: images["sushil.png"],
      full_name: "Sushil",
      designation: "Jr. DevOps Engineer",
    },
    {
      url: images["kulwinder-kaur.jpg"],
      full_name: "Kulwinder Kaur",
      designation: "Sr. Software Engineer",
    },
    {
      url: images["shyamsundar.jpg"],
      full_name: "Shyamsunder Soni",
      designation: "Sr. Frontend Developer",
    },
    {
      url: images["prince-kumar.jpg"],
      full_name: "Prince Kumar",
      designation: "Sr.Fullstack Developer",
    },
    {
      url: images["rahul-kumar.jpg"],
      full_name: "Rahul Kumar",
      designation: "Python Developer",
    },
    {
      url: images["kamal-kishore.jpg"],
      full_name: "Kamal Kishore",
      designation: "Software Engineer",
    },
    {
      url: images["shivani-saini.jpeg"],
      full_name: "Shivani Saini",
      designation: "Quality Analyst",
    },
    {
      url: images["bhargav.png"],
      full_name: "Bhargav Kumar",
      designation: "Software Engineer (Flutter)",
    },
    {
      // url: "https://career.softuvo.click/images/Manish.jpg",
      url: images["Manish.jpg"],
      full_name: "Manish",
      designation: "Accountant Executive",
    }
  ];
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our innovative teams who are bringing laurels to Softuvo</title>
        {/* <link rel="canonical" href="https://career.softuvo.click/our-team.html" /> */}
        <meta name="description" content="Innovative team build more than the great products. Get a chance to know the people behind the soaring success of Softuvo!" />
      </Helmet>
      <Header />
      <section className="banner banner--ourTeam">
        <div className="banner__commonBanner">
          <div className="commonPY">
            <div className="container">
              <div className="row">
                <div className="col-md-5 alignCenter">
                  <div>
                    <h1>Our Team</h1>
                    <p>
                      As a team, we grow, we lead, we innovate! As colleagues,
                      we support, we challenge and we inspire one another every
                      day! Get a chance to know the people behind the soaring
                      success of Softuvo!
                    </p>
                  </div>
                </div>
                <div className="col-md-7 text-end">
                  <img src={TeamImg} alt="team" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="ourteam">
          <div className="ourteam__inner commonPY">
            <div className="container">
              <div className="row">
                {data.map((row) => {
                  return (
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                      <div className="ourteamBox">
                        <div className="ourteamBox__profile">
                          <div className="employeeImg">
                            <img
                              src={row.url}
                              alt={row.full_name}
                              className="img-fluid"
                            />
                          </div>
                          <div className="employeeDetails">
                            <h4>{row.full_name}</h4>
                            <p>{row.designation}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <div>
          <Blog />
        </div>
      </section>
      <Footer />
    </div>
  );
}
export default Team;

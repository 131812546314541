import React, { useState, useEffect } from "react";
import SideNavbar from "../../views/sidenavbar";
import AdminHeader from "../adminHeader";
import ReactPaginate from "react-paginate";
import apiUrl from "../../Constant";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

function ViewDepartment() {
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState();
  const [departments, setDepartments] = useState([]);
  const [itemOffset, setItemOffset] = useState(1);
  const [currentPage, setCurrentPage] = useState();
  const [limit, setPageLimit] = useState("");

  useEffect(() => {
    loadData(itemOffset);
  }, [itemOffset, pageCount]);

  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setItemOffset(newOffset);
  };

  async function editStatus(_id, e) {
    console.log("id", _id, e, status);
    if (e === true) {
      var status = "Enable";
    } else {
      var status = "Disable";
    }
    console.log("status", status);

    const editDepartment = {
      id: _id,
      status: status,
    };
    console.log(
      "id",
      _id,
      e,
      apiUrl + "edit-department/" + _id,
      editDepartment
    );

    const response = await axios(apiUrl + "edit-department/" + _id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: editDepartment,
    });
    console.log("url", response);
    if (response.status === 201) {
      loadData(itemOffset);
      console.log("data edit succesfully");
    } else {
      console.log("error in editing data");
    }
  }

  async function loadData(itemOffset) {
    console.log(
      "itempfste",
      itemOffset,
      apiUrl + "display-department/" + itemOffset
    );
    const response = await fetch(apiUrl + "display-department/" + itemOffset, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const result = await response.json();
    console.log("result", result);
    var getDepartments = result.docs;
    var departmentsCount = result.count;
    setCurrentPage(result.currentPage);
    setPageCount(Math.ceil(departmentsCount / 6));
    setPageLimit(result.limit);
    setDepartments(getDepartments);
  }
  async function deleteHandler(id) {
    confirmAlert({
      title: "Blog Delete",
      message: "Are you sure to delete this Department ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let response = await axios.delete(
              `${apiUrl}delete-department/${id}`
            );
            if (response.status === 201 || response.status === 200) {
              loadData(itemOffset);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  return (
    <React.Fragment>
      <section className="job-main-area">
        <div className="conatiner-fluid">
          <AdminHeader />
        </div>
        <SideNavbar />
        <div className="content-wrap">
          <div className="container-fluid">
            <div className="main">
              <div className="row">
                <div className="col-lg-12 p-r-0 title-margin-right">
                  <div className="page-header">
                    <div className="page-title">
                      <h1>Department</h1>
                    </div>
                    <div className="page-title">
                      <div className="breadcrumb text-right">
                        <li>
                          <a href="/admin/dashboard">Dashboard</a>
                        </li>
                        <li className="active">Department</li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="mt-3">
                    {/* <div className="search-table text-end">
                      <form>
                        <div className="form-group">
                          <input
                            type="search"
                            placeholder="Search"
                            className="form-control w-auto ms-auto"
                          />
                        </div>
                      </form>
                    </div> */}
                    <div className="table-responsive adminCommon-table">
                      <table className="table table-striped table-hover">
                        <tr>
                          <th>Sr.No.</th>
                          <th>Department</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                        {departments.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + (currentPage - 1) * 6 + 1}</td>
                              <td>{value.department_name}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  id={index}
                                  className="checkbox"
                                  checked={value.status === "Enable"}
                                  onClick={(e) =>
                                    editStatus(value._id, e.target.checked)
                                  }
                                />
                                <label
                                  htmlFor={index}
                                  className="toggle"
                                ></label>
                              </td>
                              <td>
                                <div>
                                  <a
                                    href="javascript:;"
                                    className="greenColor me-2"
                                    onClick={(e) => {
                                      navigate(
                                        `/admin/edit-department/${value._id}`
                                      );
                                    }}
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i>
                                  </a>
                                  <a
                                    href="javascript:;"
                                    className="greenColor"
                                    onClick={(e) => {
                                      deleteHandler(value._id);
                                    }}
                                  >
                                    <i class="fa-solid fa-trash"></i>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                      <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ViewDepartment;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import apiUrl from "../../Constant";
import SideNavbar from "../../views/sidenavbar";
import AdminHeader from "../adminHeader";
import DepartmentForm from "./form";

const EditDepartment = () => {
  let params = useParams();
  let id = params.id;
  let navigate = useNavigate();
  const [data, setData] = useState("");
  async function getDepartmentDetail(id) {
    let response = await axios.get(`${apiUrl}get-department-details/${id}`);
    if (response.status === 200) {
      setData(response.data.docs);
    } else {
      navigate("/admin/view-department");
    }
  }

  useEffect(() => {
    getDepartmentDetail(id);
  }, [id]);
  return (
    <React.Fragment>
      <section className="main-job-area">
        <div className="container-fluid">
          <AdminHeader />
        </div>
        <SideNavbar />
        <div className="content-wrap">
          <div className="main">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 p-r-0 title-margin-right">
                  <div className="page-header">
                    <h1 className="page-title"> Jobs</h1>
                    <div className="page-title">
                      <ol className="breadcrumb text-right">
                        <li>
                          <a href="/admin/dashboard">Dashboard</a>
                        </li>
                        <li className="active">Edit Department</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <DepartmentForm type={"Edit"} data={data} />
            </div>
          </div>
          <div></div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default EditDepartment;

import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Blog from "../../components/blogs";
import images from "../../data/imageUpload";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import apiUrl from "../../Constant";

function JobListing() {
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    loadData(id);
  }, [id]);

  const [detail, setDetail] = useState([]);
  const [listing, setListing] = useState([]);
  const [fetchedData, setFetchedData] = React.useState(null);
  // const [skill, setSkills] = useState();

  async function getjobListing() {
    let response = await axios.get(`${apiUrl}view-jobs`);
    if (response.status === 201) {
      setListing(response.data.jobs);
      // setTotalCount(response.data.count);
    }
  }

  useEffect(() => {
    getjobListing();
  }, []);

  async function loadData(id) {
    let response = await axios({
      method: "GET",
      url: apiUrl + `get-job-detail?id=${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      setDetail(response.data.job);
    }
  }
  console.log("detail", detail);
  return (
    <div>
      <Header />
      <section className="jobListingsWrap bgDarkPurple commonPY">
        <div className="container">
          <div className="jobListings">
            <div className="jobListings__entryContent">
              <div className="jobTitle">
                {/* <div className="jobTitle__img">
                  <img src={detail.fileName} alt="" />
                </div> */}
                <div className="jobTitle__info">
                  <h2>{detail.position}</h2>
                  <ul>
                    <li>
                      <i className="fa-solid fa-briefcase"></i>{" "}
                      <span>
                        {/* From {detail.experience} year(s) of experience */}
                        {detail.experience}
                      </span>
                    </li>
                    <li>
                      <i className="fa-solid fa-wallet"></i>{" "}
                      <span>₹ {detail.salary} P.A.</span>
                    </li>
                    <li>
                      <i className="fa-solid fa-location-dot"></i>{" "}
                      <span>{detail.location}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="applyButton ">
                <Link
                  to={"/apply-job/" + id}
                  state={{ role: detail.position }}
                  className="commonButton commonButton--yellow"
                >
                  Apply
                </Link>
              </div>
            </div>
            
           
            <div className="jobListings__requirements">
              <h3>Requirements</h3>
              <div className="commonTable requirementsList">
                <table>
                  <tr>
                    <td>Role:</td>
                    <td>{detail.position}</td>
                  </tr>
                  <tr>
                    <td>Salary:</td>
                    <td>{detail.salary} P.A.</td>
                  </tr>
                  <tr>
                    <td>Industry:</td>
                    <td>IT Services & Consulting</td>
                  </tr>
                  <tr>
                    <td>Functional Area:</td>
                    <td>Engineering - Software & QA</td>
                  </tr>
                  <tr>
                    <td>Role Category:</td>
                    <td>Software Development</td>
                  </tr>
                  <tr>
                    <td>Employment Type:</td>
                    <td>Full Time, Permanent</td>
                  </tr>
                </table>
              </div>
            </div>

            <div className="jobListings__keySkills">
              <h3>Key Skills</h3>
              <div className="skills">
                <button className="">{detail.skills}</button>
                {/* {skill.map((value) => {
                  return (<button className="badge">{value}</button>);
                })} */}

                {/* <button className="badge">Photoshop</button>
                <button className="badge">Wordpress</button>
                <button className="badge">PHP</button>
                <button className="badge">Java Script</button>  */}
              </div>
            </div>

            <div className="jobListings__jobDescription">
              <h3>Job Description</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: detail.description,
                }}
              ></p>
              {/* <div className="listItems">
                <ul>
                  <li>
                    Bachelor’s degree in Computer Science or other related field
                    of study or equivalent work experience
                  </li>
                  <li>
                    Experience in web/mobile design and development experience
                  </li>
                  <li>
                    xperience designing and developing mobile applications in a
                    complex-operating environment;
                  </li>
                  <li>
                    Experience with design and architectural decisions of app
                  </li>
                  <li>
                    Testing experience, leveraging mocking/spying frameworks for
                    isolation of the subject component
                  </li>
                  <li>
                    Experience developing location-based services with MapKit,
                    Google Maps, or similar framework
                  </li>
                </ul>
              </div> */}
            </div>

            <div className="jobListings__companyProfile">
              <h3>Company Profile</h3>
              <div className="commonTable companyProfileList">
                <table>
                  <tr>
                    <td>Contact Company:</td>
                    <td>Softuvo Solutions Private Limited</td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td>career@softuvo.com</td>
                  </tr>
                  <tr>
                    <td>Address:</td>
                    <td>D-199, Industrial Area, Phase - 8B, Mohali</td>
                  </tr>
                  <tr>
                    <td>Website:</td>
                    <td>www.softuvo.com</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Blog />
      </section>
      <Footer />
    </div>
  );
}

export default JobListing;

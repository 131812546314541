import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import apiUrl from "../../Constant";
import NewsForm from "./form";
import SideNavbar from "../../views/sidenavbar";
import AdminHeader from "../adminHeader";

const EditNews = () => {
  let params = useParams();
  let slug = params.slug;
  let navigate = useNavigate();
  const [data, setData] = useState("");
  async function getNewsDetail(slug) {
    let response = await axios.get(`${apiUrl}get-news-detail?slug=${slug}`);
    if (response.status === 201) {
      setData(response.data[0]);
    } else {
      navigate("/admin/news");
    }
  }

  useEffect(() => {
    getNewsDetail(slug);
  }, [slug]);

  return (
    <>
      <section className="job-main-area">
        <div className="container-fluid">
          <AdminHeader />
        </div>
        <SideNavbar />
        <div className="content-wrap">
          <div className="conatiner-fluid">
            <div className="main">
              <div className="row">
                <div className="col-lg-12 p-r-0 title-margin-right">
                  <div className="page-header">
                    <h1 className="page-title"> News</h1>
                    <div className="flexAlign">
                      <div>
                        <ul className="breadcrumb">
                          <li className="breadcrumb-item">
                            <a href="/admin/dashboard">Dashboard</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="/admin/news">News</a>
                          </li>
                          <li className="breadcrumb-item active">Edit News</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <NewsForm type={"Edit"} data={data} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default EditNews;

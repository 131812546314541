import React, { useEffect, useState } from "react";
import axios from "axios";
import apiUrl from "../../Constant";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import NewsImg from "../../assets/images/news-banner.svg";
import BlogImg1 from "../../assets/images/blog-img02.svg";
import Blog from "../../components/blogs";
import images from "../../data/imageUpload";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function News() {
  const [currentPage, setCurrentPage] = useState(1);
  const [news, setNews] = useState([]);
  const [filtersearchResult, setFiltersearchResult] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [searchParams] = useSearchParams();
  let slugRedirection = null;
  slugRedirection = searchParams.get("slug");
  const navigate = useNavigate();
  async function getNews() {
    let response = await axios.get(`${apiUrl}display-news?page=${currentPage}`);
    if (response.status === 201) {
      setNews(response.data.news);
      setTotalCount(response.data.count);
    }
  }

  useEffect(() => {
    getNews();
  }, [currentPage]);

  let filtersearchHandler = news.filter(({ title }) =>
    title || searchFilter
      ? title === searchFilter ||
      title.toLowerCase().includes(searchFilter.toLowerCase())
      : true
  );

  const monthNameAndDateFormat = (value, year) => {
    const currDate = new Date(value);
    const currMonthName = currDate.toLocaleString("default", {
      month: "short",
    });
    const currDay = currDate.getDate();
    if (year) {
      const year = currDate.getFullYear();
      return `${currMonthName} ${currDay}, ${year}`;
    }

    return `${currMonthName} ${currDay}`;
  };

  async function pageHandler(e) {
    let newOffset = e.selected + 1;
    setCurrentPage(newOffset);
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Get updates on career news of Softuvo - News</title>
        {/* <link rel="canonical" href="https://career.softuvo.click/news.html" /> */}
        <meta name="description" content="Softuvo Career presents job news and every detail happening in the office premise. Stay tuned for updates on the events, celebrations, launches, and much more." />
      </Helmet>
      <Header />
      <section className="banner banner--news">
        <div className="banner__commonBanner">
          <div className="commonPY">
            <div className="container">
              <div className="row">
                <div className="col-md-5 alignCenter">
                  <div>
                    <h1>Inside Softuvo</h1>
                    <p>
                      {" "}
                      Sip your coffee and browse through the latest happenings
                      at Softuvo! From a collection of little stories about our
                      people, our capabilities to the changing face of our
                      company all under one umbrella!
                    </p>
                  </div>
                </div>
                <div className="col-md-7 text-end">
                  <img src={NewsImg} alt="News Banner" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="latestNews latestNews--news">
          <div class="container-lg">
            {/* <div class="row">
              <div class="col-md-12 sectionContent">
                <h2>Our Latest News</h2>
                <p>There is a hidden talent in everyone! Let’s have a look</p>
              </div>
            </div> */}

            <div class="row flip-co">
              <div class="col-sm-12 col-md-8">
                <div class="row">
                  {filtersearchHandler.map((value, index) => {
                    console.log("value", value);
                    if (value.status) {
                      return (
                        <div className="col-lg-6 col-md-6">
                          <div className="latestNews__inner mt-0 mb-3">
                            <div className="latestNews__inner__img">
                              <img
                                src={value.fileName}
                                alt={value.fileName}
                                className="img-fluid"
                              />
                              {/* <img src={BlogImg1} alt="blog1" /> */}
                            </div>
                            <div className="latestNews__inner__des">
                              <h6>{value.title}</h6>
                              <ReactReadMoreReadLess
                                charLimit={20}
                                readMoreText={"Read more"}
                                readLessText={"Read less"}
                              >
                                {/* <p dangerouslySetInnerHTML={{
                                  __html: value.content,
                                }}> */}
                                {value.content}
                                {/* </p> */}
                              </ReactReadMoreReadLess>
                              <div className="latestNews__inner__des__date">
                                <p>
                                  <img
                                    src={images["date-icon.svg"]}
                                    alt="Date Icon"
                                    className="img-fluid"
                                  />
                                  {monthNameAndDateFormat(
                                    new Date(value.date),
                                    true
                                  )}
                                </p>
                                <Link
                                  to={`/news-detail?slug=${value.slug}`}
                                // onClick={getBlogsDetail}
                                >
                                  Read more
                                  <img
                                    src={images["right-arrow2.svg"]}
                                    alt="Right Arrow"
                                    className="img-fluid"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                  <div className="col-lg-12">
                    <div className="mt-2">
                      {filtersearchHandler.length > 0 && (
                        <ReactPaginate
                          nextLabel={<i class="fas fa-arrow-right"></i>}
                          previousLabel={<i class="fas fa-arrow-left"></i>}
                          pageCount={totalCount}
                          onPageChange={pageHandler}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      )}
                    </div>
                  </div>

                  {/* <div class="col-sm-6 col-md-6">
                                <div class="latestNews__inner">
                                    <div class="latestNews__inner__img">
                                        <img src="images/blog-img01.svg" alt="Blog Image" class="img-fluid" />
                                    </div>
                                    <div class="latestNews__inner__des">
                                        <h6>UI/UX Designer</h6>
                                        <p>There are many variations of passages of Lorem Ipsum available</p>
                                        <div class="latestNews__inner__des__date">
                                            <p><img src="images/date-icon.svg" alt="Date Icon" class="img-fluid" />
                                                05/04/2022</p>
                                            <a href="news-detail.html">Read more <img src="images/right-arrow2.svg"
                                                    alt="Right Arrow" class="img-fluid" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6">
                                <div class="latestNews__inner">
                                    <div class="latestNews__inner__img">
                                        <img src="images/blog-img02.svg" alt="Blog Image" class="img-fluid" />
                                    </div>
                                    <div class="latestNews__inner__des">
                                        <h6>UI/UX Designer</h6>
                                        <p>There are many variations of passages of Lorem Ipsum available</p>
                                        <div class="latestNews__inner__des__date">
                                            <p><img src="images/date-icon.svg" alt="Date Icon" class="img-fluid" />
                                                05/04/2022</p>
                                            <a href="news-detail.html">Read more <img src="images/right-arrow2.svg"
                                                    alt="Right Arrow" class="img-fluid" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6">
                                <div class="latestNews__inner">
                                    <div class="latestNews__inner__img">
                                        <img src="images/blog-img02.svg" alt="Blog Image" class="img-fluid" />
                                    </div>
                                    <div class="latestNews__inner__des">
                                        <h6>UI/UX Designer</h6>
                                        <p>There are many variations of passages of Lorem Ipsum available</p>
                                        <div class="latestNews__inner__des__date">
                                            <p><img src="images/date-icon.svg" alt="Date Icon" class="img-fluid" />
                                                05/04/2022</p>
                                            <a href="news-detail.html">Read more <img src="images/right-arrow2.svg"
                                                    alt="Right Arrow" class="img-fluid" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="latestNews__inner">
                                    <div class="latestNews__inner__img">
                                        <img src="images/blog-img01.svg" alt="Blog Image" class="img-fluid" />
                                    </div>
                                    <div class="latestNews__inner__des">
                                        <h6>UI/UX Designer</h6>
                                        <p>There are many variations of passages of Lorem Ipsum available</p>
                                        <div class="latestNews__inner__des__date">
                                            <p><img src="images/date-icon.svg" alt="Date Icon" class="img-fluid" />
                                                05/04/2022</p>
                                            <a href="news-detail.html">Read more <img src="images/right-arrow2.svg"
                                                    alt="Right Arrow" class="img-fluid" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                </div>
              </div>
              <div class="col-md-4">
                <div class="recentNews mt-0">
                  <form>
                    {/* <h3>Search...</h3> */}
                    <div class="recentNews__searchOuter">
                      <img
                        src={images["search-white.svg"]}
                        alt="Search White"
                        class="img-fluid"
                      />
                      <input
                        type="text"
                        placeholder="News Search"
                        class="form-control"
                        value={searchFilter}
                        onChange={(e) => {
                          setSearchFilter(e.target.value);
                          setFiltersearchResult(
                            news.filter(({ title }) =>
                              title || e.target.value
                                ? title == e.target.value ||
                                title
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase())
                                : true
                            )
                          );
                        }}
                      />
                    </div>
                  </form>
                  {/* <div class="recentTags">
                    <h3>Tags</h3>
                    <div>
                      <ul
                        class="nav nav-tabs recentTags__tabs"
                        id="myTab"
                        role="tablist"
                      >
                        <li class="nav-item">
                          <button
                            class="nav-link active"
                            data-bs-toggle="tab"
                            data-bs-target="#all"
                            type="button"
                          >
                            All
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            class="nav-link"
                            data-bs-toggle="tab"
                            data-bs-target="#ui-ux"
                            type="button"
                          >
                            UI/UX
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            class="nav-link"
                            data-bs-toggle="tab"
                            data-bs-target="#php"
                            type="button"
                          >
                            PHP
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            class="nav-link"
                            data-bs-toggle="tab"
                            data-bs-target="#devops"
                            type="button"
                          >
                            Devops
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            class="nav-link"
                            data-bs-toggle="tab"
                            data-bs-target="#ios"
                            type="button"
                          >
                            iOS
                          </button>
                        </li>
                      </ul>

                      <div class="tab-content mt-3 mt-sm-5" id="recentTags">
                        <div class="tab-pane fade show active" id="all">
                          All
                        </div>
                        <div class="tab-pane fade" id="ui-ux">
                          UI/UX
                        </div>
                        <div class="tab-pane fade" id="php">
                          PHP
                        </div>
                        <div class="tab-pane fade" id="devops">
                          Devops
                        </div>
                        <div class="tab-pane fade" id="ios">
                          iOS
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div>
                    <h3>Recent News</h3>
                    <ul>
                      {filtersearchHandler.map((value, index,) => {
                        // {news.map((item) => {
                        if (value.status) {
                          return (
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(`/news-detail?slug=${value.slug}`);
                              }}
                            >
                              {value.title}
                            </li>
                          );
                        }
                      })}
                      {/* })} */}
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <div>{/* <Blog /> */}</div>
      </section>
      <Footer />
    </div>
  );
}
export default News;

import React, { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../Constant";
// import { Link } from "react-router-dom";
import images from "../../data/imageUpload";
import Header from "../../components/header";
import Footer from "../../components/footer";
import BlogImg1 from "../../assets/images/blog-img02.svg";
import { useSearchParams } from "react-router-dom"

function BlogDetail() {
  const [currentPage, setCurrentPage] = useState(1);
  const [individualBlogData, setIndividualBlogData] = useState([])
  const [blogs, setBlogs] = useState([]);
  const [slug, setSlug] = useState("");
  const [blogsdetail, setBlogsDetail] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [searchParams] = useSearchParams()
  useEffect(() => {
    getBlogsDetail();
  }, []);

  let slugRedirection = null
  // slugRedirection = searchParams.get("slug").trim()
  console.log('20', slugRedirection)

  const monthNameAndDateFormat = (value, year) => {
    const currDate = new Date(value);
    const currMonthName = currDate.toLocaleString("default", {
      month: "short",
    });
    const currDay = currDate.getDate();
    if (year) {
      const year = currDate.getFullYear();
      return `${currMonthName} ${currDay}, ${year}`;
    }

    return `${currMonthName} ${currDay}`;
  };
  async function getBlogsDetail() {
    let response = await axios.get(
      `${apiUrl}get-blog-detail?slug=${slugRedirection}`
    );
    if (response.status === 201) {
      console.log("responsess", response.data)
      setIndividualBlogData(response.data[0])
    }
  }
  console.log('49', individualBlogData)
  return (
    <div>
      <Header />
      <section class="banner banner--news">
        <div className="banner__commonBanner">
          <div className="commonPY">
            <div className="container">
              <div className="row">
                <div className="col-md-6 alignCenter">
                  <div>
                    <h1>Inside Softuvo</h1>
                    <p>
                      Sip your coffee and browse through the latest happenings at Softuvo! From a
                      collection of little stories about our people, our capabilities to the changing
                      face of our company all under one umbrella!
                    </p>
                  </div>
                </div>
                <div className="col-md-6  text-end">
                  <img
                    src={images["contact-banner.svg"]}
                    alt="Contact Banner"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>

        <section class="newsDetail commonPY">
            <div class="container">
                <div class="newsContent">
                    <div class="row">
                        <div class="col-lg-6">
                            <h5>{individualBlogData.title}</h5>
                            <p dangerouslySetInnerHTML={{
                          __html: individualBlogData.content,
                        }}></p>
                        </div>
                        <div className="col-lg-6">
                        <img src={individualBlogData.fileName} alt="blog1" />
                        </div>
                        {/* <div class="col-lg-6">
                            <img src="images/news-detail2.png" alt="News Detail"
                                class="img-fluid newsContent__rightImg" />
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

      
     <Footer />
    </div>
  )}

export default BlogDetail;

import React, { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../Constant";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import BlogImg from "../../assets/images/blog-img01.svg";
import BlogImg1 from "../../assets/images/blog-img02.svg";
import images from "../../data/imageUpload";
import ReactReadMoreReadLess from "react-read-more-read-less";

function Blog() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  async function getBlogs() {
    let response = await axios.get(
      `${apiUrl}display-blogs?page=${currentPage}`
    );
    // console.log("response", response);
    if (response.status === 201) {
      setBlogs(response.data.blogs);
      setTotalCount(response.data.count);
    }
  }

  useEffect(() => {
    getBlogs();
  }, [currentPage]);

  const monthNameAndDateFormat = (value, year) => {
    const currDate = new Date(value);
    const currMonthName = currDate.toLocaleString("default", {
      month: "short",
    });
    const currDay = currDate.getDate();
    if (year) {
      const year = currDate.getFullYear();
      return `${currMonthName} ${currDay}, ${year}`;
    }

    return `${currMonthName} ${currDay}`;
  };

  return (
    <section className="latestNews">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="row">
              <div className="col-md-12 sectionContent">
                <h2>
                  Hello?? Don't Scroll Too Fast!!
                  <br />
                  You'll Miss The Fun Brewing Inside!
                </h2>
                <p>
                  Let's Check The Latest News At Softuvo &gt;&gt;&gt;&gt;&gt;
                </p>
              </div>
            </div>
            <div className="row">
              {blogs.map((value, index) => {
                if (value.status)
                  return (
                    <div className="col-lg-6 col-md-6">
                      <div className="latestNews__inner">
                        <div className="latestNews__inner__img">
                          {/* <img src={value.fileName} alt = {value.fileName} className="img-fluid"/> */}
                          {/* <img src={BlogImg1} alt="blog1" /> */}
                          <img
                                src={value.fileName}
                                alt={value.fileName}
                                className="img-fluid"
                              />
                        </div>
                        <div className="latestNews__inner__des">
                          <h6>{value.title}</h6>
                          <ReactReadMoreReadLess
                            charLimit={20}
                            readMoreText={"Read more"}
                            readLessText={"Read less"}
                          >
                            {/* <p dangerouslySetInnerHTML={{
                                __html: value.content,
                              }}> */}
                            {value.content}
                            {/* </p> */}
                          </ReactReadMoreReadLess>

                          <div className="latestNews__inner__des__date">
                            <p>
                              <img
                                src={images["date-icon.svg"]}
                                alt="Date Icon"
                                className="img-fluid"
                              />
                              {monthNameAndDateFormat(
                                new Date(value.date),
                                true
                              )}
                            </p>
                            <Link to ={`/blogs-detail?slug=${value.slug}`} >
                            {/* <Link to={"/news-detail/" +value._id}> */}
                              Read more
                              <img
                                src={images["right-arrow2.svg"]}
                                alt="Right Arrow"
                                className="img-fluid"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
              })}
              {/* <div className="col-lg-6 col-md-6">
                <div className="latestNews__inner">
                  <div className="latestNews__inner__img">
                    <img src={BlogImg} alt="blog" />
                  </div>
                  <div className="latestNews__inner__des">
                    <h6>UI/UX Designer</h6>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available
                    </p>
                    <div className="latestNews__inner__des__date">
                      <p>
                        <img
                          src={images["date-icon.svg"]}
                          alt="Date Icon"
                          className="img-fluid"
                        />
                        05/04/2022
                      </p>
                      <Link to="/news-detail">
                        Read more
                        <img
                          src={images["right-arrow2.svg"]}
                          alt="Right Arrow"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-6 col-md-6">
                <div className="latestNews__inner">
                  <div className="latestNews__inner__img">
                    <img src={BlogImg1} alt="blog1" />
                  </div>
                  <div className="latestNews__inner__des">
                    <h6>UI/UX Designer</h6>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available
                    </p>
                    <div className="latestNews__inner__des__date">
                      <p>
                        <img
                          src={images["date-icon.svg"]}
                          alt="Date Icon"
                          className="img-fluid"
                        />
                        05/04/2022
                      </p>
                      <Link to="/news-detail">
                        Read more
                        <img
                          src={images["right-arrow2.svg"]}
                          alt="Right Arrow"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <h3>Stay connected with us!</h3>
            <div className="latestNews__inner latestNews__inner--bgBlack">
              <ul class="latestNews__inner__blogList">
                <li>
                  <div className="latestNews__inner__blogList__img">
                    <span>
                      <img
                        src={images["logo-white-s.png"]}
                        alt="Blog Image"
                        className="img-fluid"
                      />
                    </span>
                    <h4>Softuvo</h4>
                  </div>
                  <div className="latestNews__inner__blogList__des">
                    <Link to="#">
                      <h5 className="mt-2 mb-3 text-end">2 Days ago</h5>
                      
                    </Link>
                    <div className="twitterWidget">
                      <div
                        className="twitter-timeline twitter-timeline-rendered"
                        style={{
                          display: "flex",
                          maxWidth: "100%",
                          marginTop: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        <iframe
                          id="twitter-widget-0"
                          scrolling="no"
                          frameBorder={0}
                          className
                          style={{
                            position: "static",
                            visibility: "visible",
                            width: "291px",
                            height: "8984px",
                            display: "block",
                            flexGrow: 1,
                          }}
                          title="Twitter Timeline"
                          src="https://syndication.twitter.com/srv/timeline-profile/screen-name/softuvo?dnt=false&embedId=twitter-widget-0&lang=en&sessionId=dd4d4230e63395872c9242f3b24e99c0c53f6fcb&showHeader=true&showReplies=false"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center sectionContent">
            <Link
              to="/blog"
              className="commonButton commonButton--yellow m-auto"
            >
              Read More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Blog;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import images from "../../data/imageUpload";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Blog from "../../components/blogs";
import apiUrl from "../../Constant";
import ReactPaginate from "react-paginate";
import Moment from "moment";
import { Helmet } from "react-helmet";

function Blogs() {
  const navigate = useNavigate();
  const [blogs, setBlog] = useState([]);
  const [itemOffset, setItemOffset] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [searchFilter, setSearchFilter] = useState("");
  const [currentPage, setCurrentPage] = useState();
  useEffect(() => {
    loadData(itemOffset);
  }, [itemOffset, pageCount]);

  const handlePageClick = (event) => {
    const newOffset = event.selected + 1;
    setItemOffset(newOffset);
  };

  async function loadData(itemOffset) {
    const response = await fetch(apiUrl + "get-enable-blogs", {
      method: "POST",
      body: JSON.stringify({
        page: itemOffset,
      }),
      headers: { "Content-Type": "application/json" },
    });
    const result = await response.json();
    var getBlogs = result.blogs;
    var blogsCount = result.totalBlogsCount;
    setCurrentPage(result.currentPage);
    setPageCount(Math.ceil(blogsCount / 9));
    setBlog(getBlogs);
  }

  let filtersearchHandler =
    blogs
      .filter(({ title }) =>
        title || searchFilter
          ? (title) === searchFilter ||
          title
            .toLowerCase()
            .includes(searchFilter.toLowerCase())
          : true
      )
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Get latest updates on surging trends in the industry - Blog</title>
        {/* <link rel="canonical" href="https://career.softuvo.click/blog.html" /> */}
        <meta name="description" content="Stay tuned with surging technical news, updates, and trends to keep yourself updated like new technologies, launches, Google updates, popular apps." />
      </Helmet>
      <Header />
      <section className="banner banner--blog">
        <div className="banner__commonBanner">
          <div className="commonPY">
            <div className="container">
              <div className="row">
                <div className="col-md-6 alignCenter">
                  <div>
                    <h1>Blog</h1>
                    <p>
                      Need some advice on how to make your workday better and
                      shoot your overall professional growth! What’s better than
                      getting some tips on career basics from the experts
                      themselves to help you pave a better career path in the IT
                      Industry!
                    </p>
                  </div>
                </div>
                <div className="col-md-6  text-end">
                  <img
                    src={images["blog-banner.svg"]}
                    alt="Blog Banner"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blogList commonPY">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                {filtersearchHandler.map((value) => {
                  return (
                    <div className="col-lg-6 col-md-6">
                      <div className="blogList__inner">
                        <div className="blogList__inner__img">
                          <img
                            src={value.fileName}
                            alt="Blog Image"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blogList__inner__des">
                          <h6>{value.title}</h6>
                          <p>{value.meta_description}</p>
                          <div className="blogList__inner__des__date">
                            <p>
                              <img
                                src={images["date-icon.svg"]}
                                alt="Date Icon"
                                className="img-fluid"
                              />{" "}
                              {Moment(value.date).format("DD/MM/YYYY")}
                            </p>
                            <Link to={`/blogs-detail?/${value.slug}`} >
                              Read more{" "}
                              <img
                                src={images["right-arrow2.svg"]}
                                alt="Right Arrow"
                                className="img-fluid"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>



            </div>

            <div className="col-md-3">
              <div className="blogList__top">
                <form className="blogList__top__searchForm w-100">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Blog Search..."
                    value={searchFilter}
                    onChange={(e) => setSearchFilter(e.target.value)}
                  />
                  <div className="mt-4">
                    <h5 className="mb-3">Recent Blogs</h5>
                    <ul>
                      {filtersearchHandler.map((value, index,) => {
                        // {news.map((item) => {
                        if (value.status) {
                          return (
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(`/news-detail?slug=${value.slug}`);
                              }}
                            >
                              {value.title}
                            </li>
                          );
                        }
                      })}
                      {/* })} */}
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="row">

          </div>
          <ReactPaginate
            nextLabel={<i class="fas fa-arrow-right"></i>}
            previousLabel={<i class="fas fa-arrow-left"></i>}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
          {/* <div className="row">
            <div className="col-md-12">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item disabled">
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">
                        <img
                          src={images["prev.svg"]}
                          alt="Previous Icon"
                          className="img-fluid"
                        />
                      </span>
                    </a>
                  </li>
                  <li className="page-item active" aria-current="page">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">
                        <img
                          src={images["next.svg"]}
                          alt="Next Icon"
                          className="img-fluid"
                        />
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
      </section>

      <Blog />
      <Footer />
    </div>
  );
}

export default Blogs;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//CSS
// import "./App.css";
import "../src/assets/css/style.css";
import "../src/assets/admin/css/custom.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// import "../src/assets/style/style.css";
// import "../src/assets/admin/css/bootstrap.css";
import Login from "../src/views/login";
import ViewCategories from "./components/category";
import ViewCategoriesNews from "./components/category/view-news-category.jsx";

import NewsLetter from "./components/newsletter";
import EditCategory from "./components/category/edit-category";
import ForgotPassword from "./components/forgot-password";
import ResetPassword from "./views/reset-password";
import ChangePassword from "./components/change-password";
import Error from "./views/error";
import Dashboard from "./views/dashboard";
import EditBlog from "./components/blog/edit-blog";
import EditNews from "./components/news/edit-news";
import Subscriber from "./components/subscriber";
import AllBlogs from "./components/blog";
import AllNews from "./components/news";
import AddBlog from "./components/blog/add-blog";
import AddNews from "./components/news/add-news";
import DisplayJobs from "./components/jobs";
import AddCategory from "./components/category/add-category";
import AddJob from "./components/jobs/add-job";
import EditJob from "./components/jobs/edit-job";

// import parse from 'html-react-parser'
// import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import Header from "./components/header/index";
// import Footer from "./components/footer";
import Home from "./views/home/index";
import JoinPage from "./views/whyjoinPage";
import ViewDepartment from "./components/job-department/viewDepartmentList";
import Employeesaying from "./views/employeeSaying";
import Employeestories from "./views/employeeStories";
import EmployeeContribution from "./views/employeeContribution";
import EqualOpportunity from "./views/equalOpportunity";
import HybridWorkCulture from "./views/hybridWorkCulture";
import ApplyJob from "./views/applyJob";
import LifePage from "./views/lifePage";
import Team from "./views/ourteam";
import UpskillSession from "./views/upskillSession";
import UpskillSessionMore from "./views/upskillsessionMore";
import InclusionAndDiversity from "./views/inclusionAndDiversity";
import News from "./views/news";
import Contact from "./views/contactUs";
import Jobs from "./views/jobsSearch";
import JobDetails from "./views/jobDetails";
import JobListing from "./views/jobListing";
import OurCulture from "./views/ourCulture";
import BlogPage from "./views/blogPage";
import ApplicationViews from "./views/applicationViews";
import Newsdetail from "./views/newsDetail";
import BlogDetail from "./views/blogDetail";
import EmployeeDetailsItender from "./views/employeesDetails/employee-details-itender";
import EmployeeDetailsAmit from "./views/employeesDetails/employee-details-amit";
import EmployeeDetailAnita from "./views/employeesDetails/employee-details-anita";
import EmployeeDetailsDiksha from "./views/employeesDetails/employee-details-diksha";
import EmployeeDetailsGaman from "./views/employeesDetails/employee-details-gaman";
import JobDepartment from "./components/job-department/add-department";
import EditDepartment from "./components/job-department/edit-department";
import GoToTop from "../src/components/scrollToTop";
function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        newestOnTop={false}
        autoClose={5000}
        bodyClassName="toastBody"
      />
      <Router>
        <GoToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/why-join-softuvo" element={<JoinPage />} />
          <Route path="/life-at-softuvo" element={<LifePage />} />
          <Route path="/our-team" element={<Team />} />
          <Route path="/upskill-session" element={<UpskillSession />} />
          <Route path="/our-culture" element={<OurCulture />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route
            path="/inclusion-diversion"
            element={<InclusionAndDiversity />}
          />
          <Route
            path="/upskill-session-more"
            element={<UpskillSessionMore />}
          />
          <Route path="/news" element={<News />} />
          <Route path="/news-detail" element={<Newsdetail />} />
          <Route path="/blogs-detail" element={<BlogDetail />} />
          <Route path="/equal-opportunity" element={<EqualOpportunity />} />
          <Route path="/job-search/:id" element={<Jobs />} />
          <Route path="/apply-job/:id" element={<ApplyJob />} />
          <Route path="/job-listings/:id" element={<JobListing />} />
          <Route path="/job-detail" element={<JobDetails />} />
          <Route path="/application-process" element={<ApplicationViews />} />
          <Route path="/hybrid-work-culture" element={<HybridWorkCulture />} />
          <Route path="/employee-saying" element={<Employeesaying />} />
          <Route path="/employee-stories" element={<Employeestories />} />
          <Route
            path="/employee-contributions"
            element={<EmployeeContribution />}
          />
          <Route path="/contact-us" element={<Contact />} />
          <Route
            path="/employee-details-itender"
            element={<EmployeeDetailsItender />}
          />
          <Route
            path="/employee-details-amit"
            element={<EmployeeDetailsAmit />}
          />
          <Route
            path="/employee-details-anita"
            element={<EmployeeDetailAnita />}
          />
          <Route
            path="/employee-details-diksha"
            element={<EmployeeDetailsDiksha />}
          />
          <Route
            path="/employee-details-gaman"
            element={<EmployeeDetailsGaman />}
          />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/create-blog" element={<AddBlog />} />
          <Route path="/admin/create-news" element={<AddNews />} />
          <Route path="/admin/forgot-password" element={<ForgotPassword />} />
          <Route path="/admin/blog" element={<AllBlogs />} />
          <Route path="/admin/news" element={<AllNews />} />
          <Route path="/admin/edit-blog/:slug" element={<EditBlog />} />
          <Route path="/admin/edit-news/:slug" element={<EditNews />} />
          <Route path="/admin/blog-category" element={<ViewCategories />} />
          <Route path="/admin/news-category" element={<ViewCategoriesNews />} />
          <Route
            path="/admin/add-blog-category"
            element={<AddCategory dataKey={"blog"} />}
          />
          <Route
            path="/admin/add-news-category"
            element={<AddCategory dataKey={"news"} />}
          />
          <Route path="/admin/edit-category/:slug" element={<EditCategory />} />
          <Route path="/admin/change-password" element={<ChangePassword />} />
          <Route path="/admin/newsletter" element={<NewsLetter />} />
          <Route path="/admin/subscriber" element={<Subscriber />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/admin/change-password" element={<ChangePassword />} />
          <Route path="/admin/job" element={<DisplayJobs />} />
          <Route path="/admin/create-job" element={<AddJob />} />
          <Route path="/admin/get-job-detail/:id" element={<EditJob />} />
          <Route path="/admin/add-department" element={<JobDepartment />} />
          <Route
            path="/admin/edit-department/:id"
            element={<EditDepartment />}
          />
          <Route path="/admin/view-department" element={<ViewDepartment />} />
          {/* <Route path="/admin/edit-department/:slug" element={<EditDepartment />} /> */}
          <Route path="/admin" element={<Login />} />
          <Route path="/admin/*" element={<Error />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

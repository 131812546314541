import React, { useEffect, useState, ChangeEvent } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Blog from "../../components/blogs";
import images from "../../data/imageUpload";
import apiUrl from "../../Constant";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import docoumetImage from "../../assets/images/documentImage.jpg";
import IconDoc from "../../assets/images/iconDoc.png";
import { toast } from "react-toastify";
import moment from "moment";
import { typeOf } from "react-read-more-read-less";
function ApplyJob(props) {
  const location = useLocation();
  const { role } = location.state;
  let { type, data } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    loadData(id);
  }, [id]);
  const [detail, setDetail] = useState([]);
  const [isMailSend, setIsMailSend] = useState("Yes");
  const [mobile, setmobile] = useState("");
  const [isError, setIsError] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [jobValues, setJobValues] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      designation: "",
      companyName: "",
      from: "",
      to: "",
      degree: "",
      institution: "",
      sendEmail: false,
      fileName: "",
    },
  ]);

  const [preview, setPreview] = useState([]);
  const [currentlyWorkingHere, setCurrentlyWorkingHere] = useState(false);
  const [experienceCount, setExperienceCount] = useState(1);
  const [experienceDetail, setExperienceDetail] = useState([
    {
      designation: "",
      companyName: "",
      from: "",
      to: "",
    },
  ]);
  const [educationCount, setEducationCount] = useState(1);
  const [educationDetail, setEducationDetail] = useState([
    {
      degree: "",
      institution: "",
      fieldOfStudy: "",
    },
  ]);

  let handleChange = (i, e) => {
    let newFormValues = [...jobValues];
    if (e && e.target && e.target.files) {
      newFormValues[i]["fileName"] = e.target.files[0];
      setPreview(URL.createObjectURL(e.target.files[0]));
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }
    setJobValues(newFormValues);
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("fileName", jobValues[0].fileName);
    formData.append("firstName", jobValues[0].firstName);
    formData.append("lastName", jobValues[0].lastName);
    formData.append("email", jobValues[0].email);
    formData.append("mobile", jobValues[0].mobile);
    formData.append("acknowledgement", isMailSend == "Yes" ? true : false);
    formData.append("experience", JSON.stringify(experienceDetail));
    formData.append("education", JSON.stringify(educationDetail));
    formData.append("position", role);
    let response = await axios({
      method: "POST",
      url: apiUrl + "apply-job",
      data: formData,

      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 201 || response.status === 200) {
      toast.success(response.data.message);
      setJobValues([
        {
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
          sendEmail: false,
          fileName: "",
        },
      ]);
      setPreview([]);

      navigate("/job-detail");
    }
  }

  async function loadData(id) {
    const response = await axios.get(`${apiUrl}get-job-detail?id=${id}`);
    if (response.status === 200) {
      const result = await response.json();
      setDetail(result.job);
    } else {
      // navigate("/admin/blog");
    }
  }
  function addExperienceCount(e, index) {
    e.preventDefault();
    setExperienceCount(experienceCount + 1);
    setExperienceDetail((prevData) => {
      prevData[index + 1] = {
        designation: "",
        companyName: "",
        from: "",
        to: "",
      };
      return [...prevData];
    });
  }
  function decreaseExperienceCount(e, index) {
    e.preventDefault();
    if (experienceCount > 1 && experienceDetail.length > 0) {
      let experienceDetailCopy = [...experienceDetail];
      experienceDetailCopy.splice(index, 1);
      setExperienceDetail(experienceDetailCopy);
      setExperienceCount(experienceCount - 1);
    }
  }

  function addEducationCount(e, index) {
    e.preventDefault();
    setEducationCount(educationCount + 1);
    setEducationDetail((prevData) => {
      prevData[index + 1] = {
        degree: "",
        institution: "",
        fieldOfStudy: "",
      };
      return [...prevData];
    });
  }
  function decreaseEducationCount(e, index) {
    e.preventDefault();
    if (educationCount > 1 && educationDetail.length > 0) {
      let educationDetailCopy = [...educationDetail];
      educationDetailCopy.splice(index, 1);
      setEducationDetail(educationDetailCopy);
      setEducationCount(educationCount - 1);
    }
  }
  // const handleClick = () => {
  //   setToggle(!toggle)
  // };
  return (
    <div>
      <Header />
      <section className="jobListingsWrap bgDarkPurple commonPY">
        <div className="container">
          <div className="jobListings">
            <div className="jobListings__entryContent">
              {/* <div className="jobTitle">
                <div className="jobTitle__img">
                  <img src={detail.fileName} alt="" />
                </div>
                <div className="jobTitle__info">
                  <h2>{detail.position}</h2>
                  <ul>
                    <li>
                      <i className="fa-solid fa-briefcase"></i>{" "}
                      <span>
                        From {detail.experience} year(s) of experience
                      </span>
                    </li>
                    <li>
                      <i className="fa-solid fa-wallet"></i>{" "}
                      <span>₹ {detail.salary} P.A.</span>
                    </li>
                    <li>
                      <i className="fa-solid fa-location-dot"></i>{" "}
                      <span>{detail.location}</span>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="applyJob">
              <form
                method="post"
                enc="multipart/form-data"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                {jobValues.map((element, index) => {
                  return (
                    <div className="applyJobForm">
                      <div className="applyJobForm__bioData">
                        <div className="row">
                          <div className="col-sm-6 form-group">
                            <label htmlFor="">First Name</label>
                            <input
                              required
                              className="form-control"
                              name="firstName"
                              type="text"
                              placeholder="First Name"
                              value={element.firstName}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </div>
                          <div className="col-sm-6 form-group">
                            <label htmlFor="">Last Name</label>
                            <input
                              required
                              className="form-control"
                              name="lastName"
                              type="text"
                              placeholder="Last Name"
                              value={element.lastName}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </div>
                          <div className="col-sm-6 form-group">
                            <label htmlFor="">Email</label>
                            <input
                              required
                              className="form-control"
                              name="email"
                              type="email"
                              placeholder="Email"
                              value={element.email}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </div>
                          <div className="col-sm-6 form-group">
                            <label htmlFor="">Mobile</label>
                            <input
                              required
                              className="form-control"
                              name="mobile"
                              type="number"
                              // error={isError}
                              onWheel={(e) => e.target.blur()}
                              placeholder="Mobile"
                              value={mobile}
                              onChange={(e) => {
                                handleChange(index, e)
                                setmobile(e.target.value);
                                if (e.target.value.length > 10) {
                                  setIsError(true);
                                }
                                else{

                                  setIsError(false);
                                }
                              }}
                              
                            />
                            {isError &&<small>Please enter valid number.</small>}
                          </div>
                        </div>
                      </div>
                      {[...Array(experienceCount)].map(
                        (value, indexExperience) => {
                          return (
                            <div className="applyJobForm__experience">
                              {indexExperience == experienceCount - 1 && (
                                <h3>
                                  Experience
                                  <button
                                    className="addMoreData"
                                    onClick={(e) =>
                                      addExperienceCount(e, indexExperience)
                                    }
                                  >
                                    <img src={images["plus-icon.svg"]} alt="" />{" "}
                                    Add Experience
                                  </button>
                                </h3>
                              )}
                              <div>
                                {experienceCount > 1 && (
                                  <button
                                    type="button"
                                    style={{
                                      marginLeft: "1250px",
                                      color: "white",
                                    }}
                                    className="btn btn-generate ms-30 shadow-sm delIcon"
                                    onClick={(event) =>
                                      decreaseExperienceCount(
                                        event,
                                        indexExperience
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </button>
                                )}
                              </div>
                              <div className="row">
                                <div className="col-sm-6 form-group mt-0">
                                  <label htmlFor="">Designation</label>
                                  <input
                                    required
                                    className="form-control"
                                    name="designation"
                                    type="text"
                                    placeholder="Designation"
                                    value={
                                      experienceDetail[indexExperience]
                                        ?.designation
                                    }
                                    onChange={(e) =>
                                      setExperienceDetail((prevData) => {
                                        prevData[indexExperience] = {
                                          ...prevData[indexExperience],
                                          designation: e.target.value,
                                        };
                                        return [...prevData];
                                      })
                                    }
                                  />
                                </div>
                                <div className="col-sm-6 form-group mt-0">
                                  <label htmlFor="">Company Name</label>
                                  <input
                                    required
                                    className="form-control"
                                    name="companyName"
                                    type="text"
                                    value={
                                      experienceDetail[indexExperience]
                                        ?.companyName
                                    }
                                    onChange={(e) =>
                                      setExperienceDetail((prevData) => {
                                        prevData[indexExperience] = {
                                          ...prevData[indexExperience],
                                          companyName: e.target.value,
                                        };
                                        return [...prevData];
                                      })
                                    }
                                    placeholder="Company Name"
                                  />
                                </div>
                                <div className="col-12 form-group">
                                  <div className="row align-items-end">
                                    <div className="col-md-4 col-sm-6 form-group">
                                      <label htmlFor="">From</label>
                                      <DatePicker
                                        maxDate={moment().toDate()}
                                        showMonthDropdown
                                        showYearDropdown
                                        className="form-control calendar"
                                        placeholderText="mm/dd/yyyy"
                                        selected={
                                          experienceDetail[indexExperience]
                                            ?.from
                                        }
                                        onChange={(date) =>
                                          setExperienceDetail((prevData) => {
                                            prevData[indexExperience] = {
                                              ...prevData[indexExperience],
                                              from: date,
                                            };
                                            return [...prevData];
                                          })
                                        }
                                      />
                                    </div>
                                    {/* {toggle && ( */}
                                    <div className="col-md-4 col-sm-6 form-group">
                                      <label htmlFor="">To</label>
                                      <DatePicker
                                        maxDate={moment().toDate()}
                                        showMonthDropdown
                                        showYearDropdown
                                        className="form-control calendar"
                                        placeholderText="mm/dd/yyyy"
                                        selected={
                                          experienceDetail[indexExperience]?.to
                                        }
                                        onChange={(date) =>
                                          setExperienceDetail((prevData) => {
                                            prevData[indexExperience] = {
                                              ...prevData[indexExperience],
                                              to: date,
                                              working: "past",
                                            };
                                            return [...prevData];
                                          })
                                        }
                                      />
                                    </div>
                                     {/* )} */}
                                     {/* {experienceCount && !toggle && (
                                    <div className="col-md-4 col-sm-12 form-group">
                                      <div className="switchButton">
                                        <input
                                          checked={
                                            experienceDetail[indexExperience]
                                              .working == "current"
                                              ? true
                                              : false
                                          }
                                          // onClick={(e,date) => {
                                          //   if (e.target.checked == true) {
                                          //     setExperienceDetail(
                                          //       (prevData) => {
                                          //         prevData[indexExperience] = {
                                          //           ...prevData[
                                          //             indexExperience
                                          //           ],
                                          //           working: "current",
                                          //           to : date
                                          //         };
                                          //         return [...prevData];
                                          //       }
                                          //     );
                                          //   } else {
                                          //     setExperienceDetail(
                                          //       (prevData) => {
                                          //         prevData[indexExperience] = {
                                          //           ...prevData[
                                          //             indexExperience
                                          //           ],
                                          //           working: "past",
                                          //         };
                                          //         return [...prevData];
                                          //       }
                                          //     );
                                          //   }
                                          // }}
                                          type="checkbox"
                                          hidden="hidden"
                                          id={
                                            "switchNotifications" +
                                            experienceDetail[indexExperience].to
                                          }
                                          onClick={() => setToggle(!toggle)} 
                                        />
                                        <label
                                          for={
                                            "switchNotifications" +
                                            experienceDetail[indexExperience].to
                                          }
                                          className="switch"
                                          htmlFor="switchNotifications"
                                        ></label>
                                        <span onClick={() => setToggle(!toggle)} >Currently works here</span>
                                      </div>
                                    </div>
                                     )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                      {[...Array(educationCount)].map(
                        (value, indexEducation) => {
                          return (
                            <div className="applyJobForm__education">
                              {indexEducation == educationCount - 1 && (
                                <h3>
                                  Education
                                  <button
                                    className="addMoreData"
                                    onClick={(e) =>
                                      addEducationCount(e, indexEducation)
                                    }
                                  >
                                    <img src={images["plus-icon.svg"]} alt="" />
                                    Add Education
                                  </button>
                                </h3>
                              )}
                              <div>
                                {educationCount > 1 && (
                                  <button
                                    type="button"
                                    style={{
                                      marginLeft: "1250px",
                                      color: "white",
                                    }}
                                    className="btn btn-generate ms-30 shadow-sm delIcon"
                                    onClick={(event) =>
                                      decreaseEducationCount(
                                        event,
                                        indexEducation
                                      )
                                    }
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </button>
                                )}
                              </div>
                              <div className="row">
                                <div className="col-sm-6 form-group mt-0">
                                  <label htmlFor="">Degree</label>
                                  <input
                                    required
                                    className="form-control"
                                    name="degree"
                                    type="text"
                                    placeholder=""
                                    value={
                                      educationDetail[indexEducation]?.degree
                                    }
                                    onChange={(e) =>
                                      setEducationDetail((prevData) => {
                                        prevData[indexEducation] = {
                                          ...prevData[indexEducation],
                                          degree: e.target.value,
                                        };
                                        return [...prevData];
                                      })
                                    }
                                  />
                                </div>
                                <div className="col-sm-6 form-group mt-0">
                                  <label htmlFor="">
                                    Institution/School Name
                                  </label>
                                  <input
                                    required
                                    className="form-control"
                                    name="institution"
                                    type="text"
                                    value={
                                      educationDetail[indexEducation]
                                        ?.institution
                                    }
                                    onChange={(e) =>
                                      setEducationDetail((prevData) => {
                                        prevData[indexEducation] = {
                                          ...prevData[indexEducation],
                                          institution: e.target.value,
                                        };
                                        return [...prevData];
                                      })
                                    }
                                  />
                                </div>
                                <div className="col-sm-6 form-group">
                                  <label htmlFor="">Field of Study/Major</label>
                                  <input
                                    required
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={
                                      educationDetail[indexEducation]
                                        ?.fieldOfStudy
                                    }
                                    onChange={(e) =>
                                      setEducationDetail((prevData) => {
                                        prevData[indexEducation] = {
                                          ...prevData[indexEducation],
                                          fieldOfStudy: e.target.value,
                                        };
                                        return [...prevData];
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}

                      <h3>Apply to Job</h3>
                      <div className="col-12">
                        <div className="browseFile">
                          <p className="uploadText"><span>Upload your resume</span><br/>.doc,.docx,.pdf.</p>
                          {/* <input
                                required
                                type="file"
                                className="files"
                                name="files[1]"
                                multiple
                                value={element.file}
                                onChange={handleFileChange}
                              />
                              <label htmlFor="">
                                <img
                                  src={images["upload-icon.svg"]}
                                  alt="upload-icon"
                                />
                                <h5>Choose a file</h5>
                              </label> */}
                          {preview.length ? (
                            <>
                              <div
                                className="choosefile-outer"
                              >
                                <img
                                  src={IconDoc}
                                  alt={preview}
                                />
                                <button
                                  onClick={(e) => {
                                    setPreview([]);
                                  }}
                                >
                                  <i className="fas fa-times"></i>
                                </button>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <input
                            type="file"
                            onChange={(e) => handleChange(index, e)}
                          />
                        </div>
                      </div>
                      <div
                        className="col-12 form-group"
                      >
                        <label htmlFor="">
                          Wish to receive acknowledgement email?
                        </label>
                        <div className="radioButtons">
                          <div className="radio">
                            <input
                              id="radio-1"
                              name="radio"
                              type="radio"
                              value={"Yes"}
                              checked={isMailSend == "Yes" && true}
                              onChange={(e) => setIsMailSend("Yes")}
                            />
                            <label htmlFor="radio-1" className="radio-label">
                              Yes
                            </label>
                          </div>
                          <div className="radio">
                            <input
                              id="radio-2"
                              name="radio"
                              type="radio"
                              onChange={(e) => setIsMailSend("No")}
                              checked={isMailSend == "No" && true}
                            />
                            <label htmlFor="radio-2" className="radio-label">
                              No
                            </label>
                          </div>
                        </div>
                      </div>

                      <div
                        className="applyJobForm__button"
                        style={{ marginBottom: "14px" }}
                      >
                        <button
                          type="submit"
                          className="commonButton commonButton--yellow"
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>
                  );
                })}
              </form>
            </div>
          </div>
        </div>
        <Blog />
      </section>
      <Footer />
    </div>
  );
}

export default ApplyJob;
